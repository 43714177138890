.projects-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 20px;
}

.projects-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.project {
  margin: 10px;
  cursor: pointer;
  text-align: center;
}

.project img {
  width: 300px;
  height: 300px;
  object-fit: cover;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }

  @media (max-width: 480px) {
    width: 150px;
    height: 150px;
  }
}

.project-detail {
  position: relative;
  padding: 20px;
  text-align: center;
}

.projects-heading {
  font-size: 2.5em;
  color: #333;
  margin: 20px 0;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 2em;
  }

  @media (max-width: 480px) {
    font-size: 1.5em;
  }
}

.project-title {
  font-size: 2em;
  color: #333;
  margin: 10px 0;

  @media (max-width: 768px) {
    font-size: 1.8em;
  }

  @media (max-width: 480px) {
    font-size: 1.5em;
  }
}

.close-icon, .modal-close, .back-button {
  cursor: pointer;
  font-size: 24px;
  position: absolute;
  color: #000;
  background: transparent;
  border: none;
  z-index: 100;
}

.close-icon {
  top: 10px;
  right: 10px;
}

.back-button {
  background-color: #B39977;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  display: flex;
  align-items: center;
  position: absolute;
  left: 10px;
  top: 10px;
  z-index: 10;
}

.back-button i {
  margin-right: 5px;
}

.back-button:hover {
  background-color: #a67c52;
}

.project-description {
  margin-bottom: 20px;
  font-size: 18px;
  color: #555;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
}

.images {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #f5f5f5;
  padding: 20px;
}

.image-item {
  margin: 10px;
  cursor: pointer;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.image-item img {
  width: 300px;
  height: 300px;
  object-fit: cover;

  @media (max-width: 768px) {
    width: 200px;
    height: 200px;
  }

  @media (max-width: 480px) {
    width: 150px;
    height: 150px;
  }
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 0;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  z-index: 1000;

  @media (max-width: 768px) {
    max-width: 90%;
    max-height: 70%;
  }

  @media (max-width: 480px) {
    max-width: 95%;
    max-height: 60%;
  }
}

.modal-content.no-padding {
  padding: 0;
}

.modal-image {
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 80vh;
  object-fit: contain;

  @media (max-width: 768px) {
    max-height: 70vh;
  }

  @media (max-width: 480px) {
    max-height: 60vh;
  }
}

.modal-image.no-border {
  border: none;
  padding: 0;
  margin: 0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 900;
}

.swipe-icon {
  position: absolute;
  top: 50%;
  font-size: 36px;
  color: white;
  cursor: pointer;
  border-radius: 50%;
  padding: 10px;
  z-index: 200;
}

.swipe-icon.left {
  left: 10px;
}

.swipe-icon.right {
  right: 10px;
}
