
  .main-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 20px;
    width: 100%;
    background-color: white;
    font-family: "Satisfy", cursive;
    font-weight: 400;
    font-style: normal;
    /* font-size: 3rem; */
}

.image-detail {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    transition: transform 0.3s ease-in-out;
}

.image-detail.reverse {
    flex-direction: row-reverse;
}

.image-detail:hover {
    transform: scale(1.03);
}

.image { 
    width: 45%; 
    height: auto; 
    border-radius: 10px;
    padding-top: 100px;
}

.details {
    width: 45%;
    background-color: white;
    padding: 20px;
}

.details h2 {
    font-size: 2rem;
    margin-bottom: 15px;
    margin-left: 40px;
    color: #333;
    /* background-color: white; */
    
}

.details p {
    font-size: 1.5rem;
    color: #666;
    /* background-color: white; */
    line-height: 1.6;
    max-height: 400px;
    overflow-y: auto;
    padding: 20px;
    font-family: "EB Garamond", serif;
    

}

/* For tablets and medium-sized screens */
@media (max-width: 768px) {
    .image-detail {
        flex-direction: column;
        width: 90%;
    }

    .image-detail.reverse {
        flex-direction: column;
        width: 90%;
    }

    .image {
        width: 90%;
        /* margin-bottom: 20px; */
    }

    .details {
        width: 95%;
    }

    .details h2 {
        font-size: 1.8rem;
        /* margin-left: 20px; */
    }

    .details p {
        font-size: 1.3rem;
        padding: 15px;
    }
}

/* For small mobile screens (576px and below) */
@media (max-width: 576px) {
    .image-detail {
        flex-direction: column;
        gap: 20px;
    }

    .image {
        width: 100%;
        margin-bottom: 15px;
    }

    .details h2 {
        font-size: 1.6rem;
        margin-left: 10px;
    }

    .details p {
        font-size: 1.2rem;
        padding: 10px;
    }
}

/* For extra small mobile screens (400px and below) */
@media (max-width: 400px) {
    .image-detail {
        flex-direction: column;
        gap: 15px;
    }

    .image {
        width: 100%;
        margin-bottom: 10px;
    }

    .details h2 {
        font-size: 1.4rem;
        margin-left: 5px;
    }

    .details p {
        font-size: 1.1rem;
        padding: 8px;
    }
}


  
  
    