.about-us {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: 0 auto;
    /* max-width: 1200px; */
    width: 100%;
    /* padding: 20px; */
    font-family: "Satisfy", cursive;
    font-weight: 400;
    font-style: normal;
    background-color: white;
      
  }
  
  /* Each section will have a flex layout */
  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    padding: 50px;
    animation: fadeInUp 1.5s ease-in-out;
    border-bottom: 1px solid #eaeaea;
  }
  
  /* Image Container */
  .image-container {
    width:40%; 
    height:50%;
    /* max-width: 400px;  */
  }
  
  .image-container img {
    width: 100%;
    /* height: 50%; */
    border-radius: 15px;
    transition: transform 0.3s ease-in-out;
    
        
      
  }
  
  /* Details section will take 65% of the space */
  .details {
    flex-grow: 1;
    padding-left: 15px;
    /* background-color: aqua; */
    /* background-color: aqua;  */
  /* padding: 20px; */
  box-sizing: border-box;
  min-height: 200px; /* Add a minimum height */
    
  }
  
  .details h2 {
    font-size: 2.2rem;
    margin-bottom: 0.8rem;
    color: #333;
    /* background-color: aqua; */
  }
  
  .details p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #555;
    /* background-color: aqua; */
  }
  .section:nth-child(1) .details {
    padding-left: 15px;
    padding-right: 0;
    padding: 20px;
    /* background-color: #EEE6D7;  */
  }
  
  .section:nth-child(2n) .details {
    padding-left: 0;
    padding-right: 15px;
    padding: 20px;
    /* background-color: #EEE6D7; */
  }
  .section:nth-child(3) .details {
    padding-left: 15px;
    padding-right: 0;
    padding: 20px;
    /* background-color: #EEE6D7;  */
  }
  
  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(20px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Image zoom effect on hover */
  .image-container img:hover {
    transform: scale(1.05);
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .section {
      flex-direction: column;
      text-align: center;
    }
  
    .image-container {
      width: 100%;
      max-width: none; /* Full width for mobile */
    }
  
    .details {
      padding-left: 0;
      padding-right: 0;
    }
  
    .details h2 {
      font-size: 1.8rem;
    }
  
    .details p {
      font-size: 1rem;
    }
    /* Responsive for Mobile Devices */
/* Small mobile devices (320px and below) */
@media (max-width: 320px) {
  .section {
    flex-direction: column;
    text-align: center;
    padding: 20px;
  }

  .image-container {
    width: 100%;
    max-width: none;
  }

  .details {
    padding: 0;
  }

  .details h2 {
    font-size: 1.5rem;
  }

  .details p {
    font-size: 0.9rem;
  }
}

/* Medium mobile devices (375px - 414px) */
@media (min-width: 321px) and (max-width: 414px) {
  .section {
    flex-direction: column;
    text-align: center;
    padding: 25px;
  }

  .image-container {
    width: 100%;
  }

  .details {
    padding: 0;
  }

  .details h2 {
    font-size: 1.7rem;
  }

  .details p {
    font-size: 1rem;
  }
}

/* Large mobile devices (415px - 767px) */
@media (min-width: 415px) and (max-width: 767px) {
  .section {
    flex-direction: column;
    text-align: center;
    padding: 30px;
  }

  .image-container {
    width: 100%;
  }

  .details {
    padding: 0;
  }

  .details h2 {
    font-size: 1.8rem;
  }

  .details p {
    font-size: 1.05rem;
  }
}

  }
  