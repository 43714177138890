/* Form container */
.ant-form {
  background: linear-gradient(135deg, #f3f3f3, #ffffff);
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  max-width: 650px;
  margin: 60px auto;
}

/* Form heading */
h2 {
  text-align: center;
  font-size: 26px;
  margin-bottom: 30px;
  color: #222;
  font-family:"Satisfy",cursive;
  font-weight: 600;
  /* text-transform: uppercase; */
  letter-spacing: 1px;
}

/* Label styling */
.ant-form-item-label > label {
  font-weight: 500;
  color: #333;
  font-size: 14px;
  font-family: 'Poppins', sans-serif;
}

/* Input field styling */
.ant-input,
.ant-input-textarea {
  padding: 12px;
  border-radius: 6px;
  border: 1px solid #ccc;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  background-color: #fefefe;
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, 0.05);
}

.ant-input:focus,
.ant-input:hover,
.ant-input-textarea:focus,
.ant-input-textarea:hover {
  border-color: #EEE6D7;
  box-shadow: 0px 0px 8px rgba(0, 123, 255, 0.2);
}

/* TextArea adjustment */
.ant-input-textarea {
  resize: none;
}

/* Radio buttons group */
.ant-radio-group {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

/* Radio button labels */
.ant-radio-wrapper {
  font-weight: normal;
  font-size: 14px;
  color: #555;
  padding: 5px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.ant-radio-wrapper:hover {
  background-color: #f0f0f0;
}

/* Checkbox styling */
.ant-checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-top: 20px;
  color: #666;
  font-size: 14px;
}

.ant-checkbox-wrapper .ant-checkbox {
  margin-right: 10px;
}

/* Submit button */
.ant-btn-primary {
  width: 100%;
  padding: 12px;
  border-radius: 8px;
  background-color: #EEE6D7;
  border-color: #EEE6D7;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  transition: background-color 0.3s ease, border-color 0.3s ease, transform 0.2s ease;
}

.ant-btn-primary:hover {
  background-color: #c8beab;
  border-color: #c8beab;
  transform: scale(1.02);
}

/* Success/Error message styling */
.ant-message-notice {
  font-size: 15px;
}

/* Form animation */
@keyframes form-appear {
  from {
      opacity: 0;
      transform: translateY(30px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.ant-form {
  animation: form-appear 0.5s ease-in-out;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .ant-form {
      padding: 25px;
  }

  .ant-radio-group {
      flex-direction: column;
      gap: 10px;
  }
}
