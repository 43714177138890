/* Navbar Container */
.navbar {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    /* background-color: #EEE6D8; */
    background-color: white;
    padding: 10px 20px; 
    height: 40px;
    
  }
  
  /* Logo Styling */
  .navbar-logo .logo {
    height: 40px; 
    
  }
  
  /* Social Media Icons Container */
  .navbar-social-media {
    display: flex;
    gap: 40px; 
  }
  
  /* Social Media Icons */
  .social-icon {
    color: #332518;
    font-size: 1.5rem;
    text-decoration: none; 
    transition: color 0.3s ease; 
  }
  
  .social-icon:hover {
    color: #695a31; 
  }
  /* Media Query for Mobile Devices */
@media (max-width: 768px) {
  /* Navbar Container */
  .navbar {
    flex-direction: row; /* Stack logo and social icons vertically */
    padding: 10px; /* Adjust padding */
    height: auto; /* Adjust height to fit content */
  }
  
  /* Logo Styling */
  .navbar-logo .logo {
    height: 30px; /* Reduce logo size for mobile */
    margin-bottom: 10px; /* Add space between logo and social icons */
  }
  
  /* Social Media Icons Container */
  .navbar-social-media {
    justify-content: center; /* Center icons */
    gap: 20px; /* Reduce space between icons */
  }
  
  /* Social Media Icons */
  .social-icon {
    font-size: 1.2rem; /* Reduce icon size for smaller screens */
  }
}

/* Media Query for Very Small Devices (e.g., phones) */
@media (max-width: 480px) {
  /* Navbar Container */
  .navbar {
    padding: 5px; /* Further reduce padding for small devices */
  }
  
  /* Logo Styling */
  .navbar-logo .logo {
    height: 25px; /* Further reduce logo size */
  }
  
  /* Social Media Icons Container */
  .navbar-social-media {
    gap: 15px; /* Even less gap for very small screens */
  }
  
  /* Social Media Icons */
  .social-icon {
    font-size: 1rem; /* Smaller icons on very small screens */
  }
}
