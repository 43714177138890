/* MenuBar Container */
.menubar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 70px;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: "EB Garamond", serif;
}

/* Hide menu on smaller screens */
.menubar-menu {
  list-style-type: none;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  padding: 0;
  margin: 0;
}

/* Show hamburger toggle on smaller screens */
.hamburger {
  display: block;
  margin-left: 20px;
  margin-top: 20px;
}

/* Show menu and hide toggle on bigger screens */
@media (min-width: 768px) {
  .menubar-menu {
    display: flex;
    flex-direction: row; 
    justify-content: space-around;
    align-items: center;
  }
  .hamburger {
    display: none;
  }
}

/* MenuBar Items */
.menubar-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin: 30px;
  margin-right: 20px;
}

/* Adding red vertical line as a separator */
.menubar-item:not(:last-child)::after {
  content: "";
  position: absolute;
  right: 0;
  height: 100%;
  width: 10px;
  background-color: #B39978;
  /* background-color: red; */
  /* margin-left: 90px; */
}

.menubar-link {
  text-decoration: none;
  color: #333;
  padding: 10px 20px;
  background-color: transparent;
  display: inline-block;
  transition: background-color 0.3s, border 0.3s;
  cursor: pointer;
  border: 0px solid transparent;
  box-sizing: border-box;
}

/* Hover Effect */
.menubar-link:hover {
  border-radius: 2px;
  cursor: pointer;
}

.menubar-link:hover::after {
  content: "";
  position: absolute;
  bottom: 35px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #B39978;
}

/* Open menu on smaller screens when toggle is clicked */
.menubar-menu.open {
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  background-color: white;
  z-index: 1;
  width: 40%;
  margin-left: 60px;

}